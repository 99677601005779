export const PURCHASE_AGREEMENT = `
제1조(목적) 
본 약관은 어문저작물의 이용허락에 관하여 권리자, 플랫폼 및 이용자 사이의 권리와 의무를 규율함에 그 목적이 있다.

제2조(이용허락 등) 
① 권리자 및 플랫폼은 권리자가 이용자가 이용하고자 하는 어문저작물(이하 ‘대상저작물’)의 저작재산권자임을 진술 및 보증한다.
② 권리자는 이용자가 공연권 구매 시 이용허락기간 동안 대상저작물을 공연하는 것을 허락한다. 이용허락기간은 구매일로부터 1년이며, 공연 횟수는 각 공연권 구매 당 1회로 한정한다. 또한, 이용자는 플랫폼에 예상공연일자를 입력하고 그 일자에 공연하여야 하며, 변경은 플랫폼을 통하여 진행한다.
③ 이용자가 대본 구매 시, 권리자는 플랫폼이 1년간 이용자에게 대상저작물을 제공하는 것을 허락한다. 이용자는 구매일로부터 1년간 대본을 플랫폼에서 다운로드 할 수 있다.
④ 이용자는 권리자의 저작인격권을 침해하거나, 권리자의 명예∙신용을 훼손하는 방식으로 대상저작물을 이용하여서는 아니 된다.

제3조(대상저작물의 제공 등) 
① 플랫폼은 .pdf 파일 형태의 대상저작물을 플랫폼에 업로드하는 방식으로 이용자에게 제공한다. 대상저작물 파일은 텍스트의 복사 등 편집이 가능한 상태여야 한다.
② 이용자는 이용허락기간이 만료되거나, 본 약관이 해지 등으로 종료하는 경우 전항에 따라 제공받은 대상저작물을 즉시 반환 또는 폐기하여야 한다.

제4조(이용현황의 제공 등) 
이용자는 권리자 또는 플랫폼이 요구하는 경우 월 1회를 넘지 않는 범위 내에서 대상저작물의 이용횟수, 이용매체 등 이용현황에 관한 자료를 제공하여야 한다.

제5조(권리침해 등에 대한 대응) 
당사자는 제3자가 권리자의 저작재산권을 침해한 사실이나 제3자가 대상저작물과 관련하여 저작재산권에 관한 문제제기를 한 사실을 인지한 경우 바로 다른 당사자에게 알린다.

제6조(권리∙의무의 양도금지) 
당사자는 다른 모든 당사자의 사전 서면 동의 없이 본 약관상의 권리·의무를 제3자에게 양도하거나 담보의 목적으로 제공할 수 없다.

제7조(비밀유지) 
당사자는 본 약관의 내용 및 본 약관과 관련하여 알게 된 다른 당사자의 업무상 비밀을 본 약관의 범위 내에서만 사용하여야 하고, 제3자에게 정당한 사유 없이 누설하거나 공개하여서는 아니된다. 본 조의 비밀유지의무는 약관이 종료된 후에도 [2]년 동안 유지된다.

제8조(진술 및 보증) 
① 권리자 및 플랫폼은 본 약관의 체결 및 이행을 제한하거나 금지하는 어떠한 사실적·법률적 문제도 없음을 진술 및 보증한다.
② 권리자는 대상저작물이 다른 제3자의 저작권 등 지식재산권 및 기타의 권리를 침해하지 않음을 진술 및 보증한다.

제9조(약관의 해지)
① 당사자는 상대방이 정당한 이유 없이 본 약관을 위반하는 경우, 상당한 기간을 정하여 상대방에게 그 시정을 촉구하고, 상대방이 그 기간이 지나도록 의무 위반을 시정하지 아니하는 경우에는 약관을 해지할 수 있다. 다만, 상대방이 명백한 시정 거부의사를 표시하였거나 위반 사항의 성격상 시정이 불가능하다는 것이 명백히 인정되는 경우에는 촉구없이 약관을 해지할 수 있다
② 권리자는 다음 각 호의 경우 본 약관을 즉시 해지할 수 있다.
1. 이용자의 회생절차의 개시 결정
2. 이용자의 파산선고
③ 이용자는 다음 각 호의 경우 본 약관을 즉시 해지할 수 있다.
1. 권리자의 회생절차의 개시 결정
2. 권리자의 파산선고
④ 본 약관에 대한 해지권의 행사는 상대방에 대한 손해배상청구권 행사에 영향을 미치지 아니한다.

제 10 조(청약철회) 
① 이용자는 다음과 같은 경우에 청약철회 및 환불을 요구할 수 있다.
1.	공연권 구매 이후 2주 이내, 미 공연 시
2.	제공받은 대본에 이용하기 힘들 정도의 하자가 있는 경우
② 플랫폼에서 대본 구매 시에는 디지털 콘텐츠이기에 특별한 경우 이외에는 청약철회가 불가하다. 대신, 플랫폼은 구매 전 미리보기를 제공한다.

제11조(손해배상) 
당사자가 정당한 이유 없이 본 약관을 위반하는 경우, 그로 인하여 다른 당사자에게 발생한 모든 손해를 배상할 책임이 있다.

제12조(불가항력) 천재지변, 전쟁, 폭동, 감염병 등의 불가항력적 사유로 당사자가 본 약관상 의무를 이행하지 못하거나 지체하는 경우에는 서로의 책임을 면제하며, 상호 합의
하여 약관 내용을 공평하게 변경한다.

제13조(분쟁해결) 
① 본 약관과 관련하여 분쟁이 발생한 경우 당사자는 상호 협의하여 분쟁을 해결하기 위하여 노력한다.
② 당사자가 본 약관에 관련된 소송을 제기하는 경우 플랫폼의 주소지를 관할하는 법원을 제1심 관할법원으로 한다.

제14조(본 약관의 효력) 
① 본 약관의 효력은 당사자가 본 약관서에 동의한 때로부터 발생한다.
② 본 약관은 그 내용과 관련하여 협의, 논의, 합의, 회의록, 비망록, 메모, 이메일, 양해각서 기타 그 명칭 여하를 불문하고 과거로부터 본 약관 체결일까지 당사자 간의 일체의 구두 또는 서면의 합의나 의사에 우선하며, 그와 같은 구두 또는 서면의 합의나 의사는 본 약관의 내용과 충돌하거나 상반되는 한 효력이 없다.

제15조(기타) 
본 약관에 명시되지 않은 사항은 당사자가 성의를 갖고 상호 협의를 통해 결정하되, 이견이 있을 경우에는 저작권법 등 관련 법령, 일반적인 상관례 및 약관 해석의 원칙에 따른다.
`;

export const REFUND_POLICY = ` 대본은 구매 후 환불이 불가합니다. 구매 시점부터 
1년까지 자유롭게 다운로드 가능합니다. 

 공연권은 구매 후 2주 이내 공연을 하지 않았을 때만 
환불이 가능합니다. 구매 후 2주 이전이어도 공연을 한 
이후에는 환불이 불가합니다. 또한, 1년 이내 미공연 시 
공연권이 사라지니 유의하시기 바랍니다. 

 대본 및 공연권 구매 후 마이페이지의 ‘구매한 작품’ 
페이지를 확인해주시기 바랍니다.`;
