import MainNav from "./pages/MainNav";
// import Main from "./pages/Main";
// import NewMain from "./pages/NewMain";
// import Main_Test from "./pages/Main_Test";
import MainVer1 from "./pages/MainVer1.jsx";
import Footer from "./pages/Footer";

import "./App.css";

function App() {
  return (
    <div className="App">
      <MainNav />
      {/*<Main />*/}
      {/*<NewMain />*/}
      {/*<Main_Test />*/}
      <MainVer1 />
      <Footer />
    </div>
  );
}

export default App;
